import * as React from 'react'
import { Link } from 'gatsby'
import { FaCommentDots } from 'react-icons/fa'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { useUserAction } from './client-identity'
import { LeftIcon } from './button-icon'
import { useToast } from './toast'

export const ActionButtons: React.FC<{ addMargin?: boolean }> = ({
  addMargin = true,
}) => {
  return (
    <div
      className={
        'flex flex-col md:flex-row md:space-x-3.5 space-y-3.5 md:space-y-0'
      }
    >
      <Link className={'btn btn-purple'} to={'/cerrajeria'}>
        Cerrajería
      </Link>
      <Link className={'btn btn-blue'} to={'/electricistas'}>
        Electricista
      </Link>
      <Link className={'btn btn-green'} to={'/plomeria'}>
        Plomería
      </Link>
    </div>
  )
}

export interface ShopButtonProps extends React.HTMLProps<HTMLButtonElement> {}

export const ShopButton = React.forwardRef<HTMLButtonElement, ShopButtonProps>(
  ({ type = 'button', ...props }, ref) => {
    const { toast, component } = useToast()
    const [send] = useUserAction()

    return (
      <>
        {component}
        <button
          ref={ref}
          className={'btn btn-purple'}
          onClick={ev => {
            trackCustomEvent({
              category: 'button',
              action: 'click',
              label: `want-chat`,
            })

            toast({
              title: '¡Hola!',
              description:
                'Estamos trabajando para que muy pronto tengas nuestro catálogo disponible.',
              status: 'info',
              duration: 10000,
              isClosable: true,
            })

            send('Catálogo')

            props.onClick?.(ev)
          }}
          {...props}
        >
          <LeftIcon>
            <FaCommentDots className={'text-xl'} />
          </LeftIcon>
          Catálogo
        </button>
      </>
    )
  },
)
