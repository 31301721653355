import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { FaTimes } from 'react-icons/fa'
import { DefaultContainer } from './containers'

export const useToast = () => {
  const [component, setComponent] = React.useState<React.ReactNode>()

  return React.useMemo(() => {
    return {
      component,
      close: () => setComponent(null),
      toast: (props: ToastProps) => {
        const timer = setTimeout(() => setComponent(null), props.duration)
        const removeTimer = () => {
          window.clearTimeout(timer)
        }

        const container = document.querySelector('#toast-container')

        if (!container) {
          throw new Error('The "#toas-container" element is not available')
        }

        setComponent(
          ReactDOM.createPortal(
            <Toast
              onUnmount={removeTimer}
              onClose={() => {
                setComponent(null)
              }}
              {...props}
            />,
            container,
          ),
        )
      },
    }
  }, [component])
}

export interface ToastProps {
  duration: number
  title: string
  description: string
  status: string
  isClosable: boolean
  onUnmount?: () => void
  onClose?: () => void
}

export const Toast: React.FC<ToastProps> = ({ onUnmount, ...props }) => {
  React.useEffect(() => {
    return () => {
      onUnmount?.()
    }
  }, [onUnmount])

  return (
    <div className={'fixed bottom-5 inset-x-0'}>
      <DefaultContainer>
        <div
          className={'relative bg-blue-600 p-3 text-white rounded shadow-md'}
        >
          {props.isClosable && (
            <button
              aria-label={'Cerrar'}
              className={'absolute p-3.5 top-0 right-0 text-lg'}
              children={<FaTimes />}
              onClick={() => {
                props.onClose?.()
              }}
            />
          )}
          <h3 className={'text-xl font-medium'}>{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </DefaultContainer>
    </div>
  )
}
