import * as React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/default-layout'
import { SEO, getLDJSON } from '../components/seo'
import { SectionOne, SectionTwo, Contact } from '../components/page'
import { MainImage } from '../components/image'
import { DefaultLogo } from '../components/logo'
import { ActionButtons, ShopButton } from '../components/action-buttons'
import { LocationSplitList } from '../components/locations'
import { Slider } from '../components/slider'
import { Strategy } from '../components/strategy'

const Home: React.FC<PageProps> = () => {
  return (
    <>
      <SEO
        article={false}
        title={'Ferretería a domicilio'}
        description={
          'Servicio de ferretería a domicilio las 24 horas de día en Bogotá. Distribuimos productos para construcción, herramientas y productos ferreteros.'
        }
      >
        <script
          type="application/ld+json"
          children={getLDJSON('home', 'HardwareStore')}
        />
      </SEO>
      <Layout theme={'blue'} visible={{ home: true }} logo={<DefaultLogo />}>
        <Slider
          id={'inicio'}
          // mt={'80px'}
          wallpaper={'homeSlider'}
          overlay={0.8}
          business={'Domicilios 24/7'}
          pageTitle={'Ferretería a Domicilio'}
          pageDescription={
            <>
              <span>
                Por compras superiores a <b>$100.000 COP</b> obtén el domicilio
                gratis.{' '}
              </span>
              <span className={'hidden md:inline-block'}>
                Ofrecemos servicios de ferretería, cerrajería, electricista y
                plomería en Bogotá las 24 horas del día. Distribuimos productos
                para la construcción como bloques, ladrillos, gravas, arenas,
                plásticos, cartón, ácidos, cemento, etc. Contamos con marcas
                como: Diaco, Eternit, Acesco, Ajover, Black & Decker, Sika,
                Pintuco, Holmic, Pavco, Stanley, etc.
              </span>
            </>
          }
          action={<ShopButton />}
          subTitle={'Ferretería las 24 horas del día'}
        />

        <Strategy
          id={'estrategia'}
          pageTitle={'Ferretería herramientas y productos'}
          pageSubTitle={
            <>
              Te ayudamos a ahorrar tiempo y dinero. Ofrecemos{' '}
              <strong>servicio de ferretería las 24 horas</strong> del día con
              tiempos de respuesta muy cortos; por lo general nuestros{' '}
              <strong>servicios son inmediatos</strong> dependiendo del lugar
              donde te encuentres.
            </>
          }
          textOne={
            <>
              Cuéntanos tu problema, nosotros analizamos la situación, te
              ofrecemos un precio justo y te informamos el{' '}
              <strong>tiempo estimado</strong> para solucionar el inconveniente.
            </>
          }
          textTwo={
            <>
              <b>No somos intermediarios</b>, pero debido a que contamos con
              varias <strong>sedes en Bogotá</strong>, podemos desplazarnos al
              lugar donde te encuentres sin ningún problema y en un tiempo muy
              corto.
            </>
          }
          textThree={
            <>
              Somos <strong>profesionales certificados</strong> que cuentan con
              bastante tiempo de experiencia. Ofrecemos soluciones simples y
              eficientes para todo tipo de problema o emergencia las 24 horas
              del día.
            </>
          }
        />

        <SectionOne
          id={'cobertura'}
          image={
            <MainImage alt={'Desplazamiento en vehículo'} reference={'woman'} />
          }
          pageTitle={'No importa donde te encuentres, allí estaremos.'}
          description={
            <>
              <p className={'mb-4'}>
                Disponemos de varias sedes distribuidas en toda{' '}
                <strong>Bogotá</strong>, nuestros profesionales cuentan con
                transporte óptimo para desplazarce al lugar que desees, con las
                herramientas necesarias. Estos son algunos de los lugares de
                alta demanda:
              </p>
              <LocationSplitList columns={2} />
            </>
          }
        />

        <SectionTwo
          id={'servicios'}
          image={
            <MainImage
              alt={'Herramientas de ferretería'}
              reference={'homeSectionTwo'}
              className={'rounded-lg'}
            />
          }
          pageTitle={'Servicios para tu hogar u oficina.'}
          description={
            <>
              <p>
                No importa la hora en la que necesites el servicio o la{' '}
                <strong>emergencia</strong> que surja, siempre estaremos
                disponibles para solucionar tus problemas en:{' '}
                <strong>
                  Cerrajería residencial, empresarial y automotriz
                </strong>
                ,{' '}
                <strong>
                  Apertura e instalación de toda clase de cerraduras
                </strong>
                , <strong>Cambio de guardas</strong>, etc.
              </p>
              <p>
                También ofrecemos servicio de{' '}
                <strong>electricistas 24/7 en Bogotá</strong> (Electricistas
                industriales, comerciales y residenciales). Instalación de
                duchas eléctricas, reparación de cortos eléctricos, estufas
                eléctricas, neveras, lavadoras, hornos eléctricos y mucho más.
              </p>

              <ActionButtons />
            </>
          }
        />

        <Contact
          id={'contacto'}
          business={'HardwareStore'}
          businessName={'Ferretería'}
          priceRange={"$10.000 - $12'000.000"}
        />
      </Layout>
    </>
  )
}

export default Home
